(function () {
  var heroCarousel = document.getElementById('heroCarousel');

  heroCarousel.addEventListener('slide.bs.carousel', function (e) {
    if (e.relatedTarget.querySelector('.hero-carousel__content--dark')) {
      heroCarousel.classList.add('carousel-dark');
    }
    else {
      heroCarousel.classList.remove('carousel-dark');
    }
  })
})();
